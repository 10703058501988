import React from 'react'
// import { Link, graphql } from 'gatsby' //'Link' is defined but never used. //'graphql' is defined but never used.

import Layout from '../components/layout'
import Seo from '../components/seo'
import BigHeader from '../components/bigHeader'
import ValueProp from '../components/valueProp'
import Showcase from '../components/showcase'
import Features from '../components/features'
import Faq from '../components/faq'
import Cta from '../components/cta'

const title = '<b>analytics</b> and <b>bug tracking</b> for MacOS apps'
const description = 'Real-time analytics with offline tracking designed for Swift. <br/>As simple as including the package.'

export default ({ data, location }) => {
  return (
    <Layout isHome>
      <Seo title={title} description={description} />
      <BigHeader what='Swift' ogLang='swift' title={title} description={description} />
      <Showcase />
      <ValueProp />
      <Features what='swift' />
      <Cta />
      <Faq />
    </Layout>
  )
}
